<template>
  <v-container class="mb-8 my-15 py-5 px-4">
    <v-card elevation="1">
      <v-card-title>
        Ficha de Plan Operativo
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col class="col-6">
            <v-text-field
                type="text"
                v-model="proyecto.nombre"
                label="Nombre del Proyecto"
                placeholder="Nombre del Proyecto"
                hint="En en este campo puede crear el nombre del proyecto"
                maxlength="1000"
                counter="1000"
                :rules="[value => !!value || 'Esta informacion es REQUERIDA para el ingreso de Hitos y Gastos']"
            ></v-text-field>
          </v-col>
          <v-col class="col-6">
            <v-text-field
                type="text"
                v-model="proyecto.unidadEncargada"
                label="Nombre de la Unidad Academica encargada"
                placeholder="Nombre de la Unidad Academica encargada"
                hint="En en este campo puede visualizar el nombre de la unidad academica encargada"
                :disabled=true
            ></v-text-field>
          </v-col>
        </v-row>
        <v-divider class="mt-7 mb-3"/>
        <div class="text-left"><h3>Unidades Académicas Asociadas (opcional)</h3></div>
        <v-row>
          <v-col>
            <v-select
                chips
                multiple
                :items="facultadesAcademicas"
                :item-text="'ccNombre'"
                :item-value="'id'"
                v-model="proyecto.facultadesAcademicas"
                label="Facultades Académicas para las Unidades Académicas asociadas (opcional)"
                placeholder="Seleccione facultad académica para las Unidades Académicas asociadas (opcional)"
                hint="La selección de facultades académicas para las Unidades Académicas asociadas es opcional (opcional)"
                @change = "setUnidadesAcademicas()"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select
                chips
                multiple
                :items="unidadesAcademicas"
                :item-text="'ccNombre'"
                :item-value="'id'"
                v-model="proyecto.unidadesAcademicas"
                label="Unidades Académicas Asociadas (opcional)"
                placeholder="Seleccione Unidades Académicas asociadas (opcional)"
                hint="La selección de Unidades Académicas asociadas es opcional (opcional)"
            ></v-select>
          </v-col>
        </v-row>
        <v-divider class="mt-7 mb-3"/>
        <div class="text-left"><h3>Encargado/a del Proyecto</h3></div>
        <v-row>
          <v-col class="col-6">
            <v-text-field
                type="text"
                v-model="proyecto.nombreEncargado"
                label="Nombre del Encargado/a de Proyecto"
                placeholder="Nombre del Encargado/a de Proyecto"
                hint="En en este campo puede crear el nombre del encargado/a y responsable proyecto"
                maxlength="255"
                counter="255"
                :rules="[value => !!value || 'Esta informacion es REQUERIDA para el ingreso de Hitos y Gastos']"
            ></v-text-field>
          </v-col>
          <v-col class="col-6">
            <v-text-field
                type="email"
                v-model="proyecto.emailEncargado"
                label="Email del Encargado/a de Proyecto"
                placeholder="Email del Encargado/a de Proyecto"
                hint="En en este campo puede crear el email del encargado/a de proyecto"
                maxlength="255"
                counter="255"
                :rules="[value => !!value || 'Esta informacion es REQUERIDA para el ingreso de Hitos y Gastos']"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
                type="text"
                v-model="proyecto.phoneEncargado"
                label="Teléfonos del Encargado/a de Proyecto"
                placeholder="Teléfonos del Encargado/a de Proyecto"
                hint="En en este campo puede crear los números de telefono del encargado/a y responsable proyecto"
                maxlength="255"
                counter="255"
                :rules="[value => !!value || 'Esta informacion es REQUERIDA para el ingreso de Hitos y Gastos']"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-divider class="mt-7 mb-3"/>
        <div class="text-left"><h3>Ejes Estratégicos</h3></div>
        <v-row>
          <v-col>
            <v-select
                chips
                multiple
                :items="ejesEstrategicos"
                :item-text="'nombreEje'"
                :item-value="'id'"
                v-model="proyecto.ejesEstrategicos"
                label="Eje Estratégico"
                placeholder="Seleccione ejes estrategicos"
                hint="El sistema permite la seleccion de multiples ejes estrategicos"
                @change="limpiaMisionalyEstrategias()"
                :rules="[value => value.length>0 || 'Esta informacion es REQUERIDA para el ingreso de Hitos y Gastos']"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select
                chips
                multiple
                :items="areasMisionales"
                :item-text="'nombreArea'"
                :item-value="'id'"
                v-model="proyecto.areasMisionales"
                label="Areas Misionales"
                placeholder="Seleccione areas misionales"
                hint="El sistema permite la seleccion de multiples areas misionales"
                @change = "setEstrategias()"
                :rules="[value => value.length>0 || 'Esta informacion es REQUERIDA para el ingreso de Hitos y Gastos']"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select
                chips
                multiple
                :items="estrategias"
                :item-text="'name'"
                :item-value="'id'"
                v-model="proyecto.estrategias"
                label="Estrategias"
                placeholder="Seleccione Estrategias"
                hint="El sistema permite la seleccion de multiples estrategias"
                :rules="[value => value.length>0 || 'Esta informacion es REQUERIDA para el ingreso de Hitos y Gastos']"
            ></v-select>
          </v-col>
        </v-row>
        <v-divider class="mt-7 mb-3"/>
        <div class="text-left pb-5"><h3>Planteamiento</h3></div>
        <v-row>
          <v-col>
            <v-textarea
                filled
                name="input-7-4"
                label="Problematica"
                color="primary-color"
                no-resize
                v-model="proyecto.problematica"
                counter
                maxlength="2048"
                :rules="[value => value.length <= 2048 || 'Maximo 2048 caracteres', value => !!value || 'Esta informacion es REQUERIDA para el ingreso de Hitos y Gastos']"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
                class="mt-3"
                label="Objetivo General del Proyecto"
                placeholder="Objetivo General del Proyecto"
                counter="1200"
                maxlength="1200"
                :must="['required']"
                v-model=proyecto.objetivoGeneral
                :rules="[value => !!value || 'Esta informacion es REQUERIDA para el ingreso de Hitos y Gastos']"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
                class="mt-3"
                label="Primer objetivo Específico del Proyecto"
                placeholder="Primer objetivo Específico del Proyecto"
                counter="1200"
                maxlength="1200"
                :must="['required']"
                v-model=proyecto.objetivo1
                :rules="[value => !!value || 'Esta informacion es REQUERIDA para el ingreso de Hitos y Gastos']"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
                class="mt-3"
                label="Segundo objetivo Específico del Proyecto (opcional)"
                placeholder="Segundo objetivo Específico del Proyecto (opcional)"
                counter="1200"
                maxlength="1200"
                v-model=proyecto.objetivo2
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
                class="mt-3"
                label="Tercer objetivo Específico del Proyecto (opcional)"
                placeholder="Tercer objetivo Específico del Proyecto (opcional)"
                counter="1200"
                maxlength="1200"
                v-model=proyecto.objetivo3
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-textarea
                filled
                name="input-7-4"
                label="Resumen del Proyecto"
                color="primary-color"
                no-resize
                counter
                :maxlength="2048"
                :rules="[value => value.length <= 2048 || 'Maximo 2048 caracteres', value => !!value || 'Esta informacion es REQUERIDA para el ingreso de Hitos y Gastos']"
                v-model="proyecto.resumen"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-textarea
                filled
                name="input-7-4"
                label="Impacto esperado y principales beneficiados del Proyecto (directo e indirectos)"
                color="primary-color"
                no-resize
                counter
                :maxlength="2048"
                :rules="[value => value.length <= 2048 || 'Maximo 2048 caracteres', value => !!value || 'Esta informacion es REQUERIDA para el ingreso de Hitos y Gastos']"
                v-model="proyecto.impacto"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn style="background-color: #EA7730; color: white"
               dark class="mr-md-1" @click="$router.push('/ficha-plan-operativo')">Volver</v-btn>
        <v-spacer></v-spacer>
        <v-btn style="background-color: #EA7730; color: white"
               dark class="mr-md-1" @click="update()">Guardar</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>

</template>

<script>

import { mapGetters, mapActions } from "vuex";
import Swal from "sweetalert2";

export default {
  name: 'application-form',
  data () {
    return {
      ejesEstrategicos: [],
      areasMisionales: [],
      estrategias: [],
      facultadesAcademicas: [],
      unidadesAcademicas: [],
      proyecto: {
        ejesEstrategicos: [],
        areasMisionales: [],
        estrategias: [],
        nombre: "",
        unidadEncargada: "",
        facultadesAcademicas: [],
        unidadesAcademicas: [],
        nombreEncargado:"",
        emailEncargado: "",
        phoneEncargado:"",
        problematica: "",
        objetivoGeneral: "",
        objetivo1: "",
        objetivo2: "",
        objetivo3: "",
        resumen:"",
        impacto: "",
      }
    }
  },
  components: {
  },
  methods: {
    ...mapActions('main', [
      'getAllEjeEstrategico',
      'getByTipoYMayor',
      'getMenoresByIdMayor',
      'getAllAreaMisional',
      'getAllEstrategiasByEjeAndTransversal',
      'createProyecto',
      'getProyectoById',
      'updateProyecto'
    ]),
    update(){
      let formData = {
        ccId: this.info.id,
        idEstrategias: this.proyecto.estrategias,
        idUnidadesAliadas: this.proyecto.unidadesAcademicas,
        form: {
          id: this.id,
          anexoEncargado: this.proyecto.phoneEncargado,
          emailEncargado: this.proyecto.emailEncargado,
          impacto: this.proyecto.impacto,
          nombre: this.proyecto.nombre,
          nombreEncargado: this.proyecto.nombreEncargado,
          objetivoEspecifico1: this.proyecto.objetivo1,
          objetivoEspecifico2: this.proyecto.objetivo2,
          objetivoEspecifico3: this.proyecto.objetivo3,
          objetivoGeneral: this.proyecto.objetivoGeneral,
          problematica: this.proyecto.problematica,
          resumen: this.proyecto.resumen,
        }
      }
      this.updateProyecto(formData)
          .then(response => {
            console.log(response.data.response);
            Swal.fire({
              title: 'Ficha Plan Operativo',
              text: 'Se ha actualizado de forma exitosa',
              confirmButtonColor: '#EA7600',
              confirmButtonText: 'Aceptar',
              allowOutsideClick: false,
              icon: 'success',
            }).then((result)=>{
              if (result.isConfirmed)
                this.$router.replace('/ficha-plan-operativo');
            });
          })
          .catch(err => console.log(err))
    },
    limpiaMisionalyEstrategias(){
      this.proyecto.areasMisionales = [];
      this.estrategias = [];
      this.proyecto.estrategias = [];
    },
    setEjesEstrategico(){
      this.getAllEjeEstrategico().then(response => {
        this.ejesEstrategicos = response.data.response;
      }).catch(err => {
        console.log(err);
      })
    },
    setAreasMisionales(){
      this.getAllAreaMisional().then(response => {
        this.areasMisionales = response.data.response;
      }).catch(err => {
        console.log(err);
      })
    },
    setEstrategias(){
      this.estrategias = [];
      this.proyecto.estrategias = [];
      this.proyecto.ejesEstrategicos.forEach((eje)=>{
        this.proyecto.areasMisionales.forEach((area)=>{
          this.getAllEstrategiasByEjeAndTransversal({"eje": eje, "misional": area}).then(response => {
            response.data.response.forEach((item)=>{
              this.estrategias.push(item);
            })
          }).catch(err => {
            console.log(err);
          });
        });
      });
    },
    setFacultadesAcademicas(){
      this.getByTipoYMayor({
        data: true,
        type: 'académica'
      }).then(response => {
        this.facultadesAcademicas = response.data.response;
      }).catch(err => {
        console.log(err);
      })
    },
    setUnidadesAcademicas() {
      this.unidadesAcademicas = [];
      this.proyecto.unidadesAcademicas = [];
      this.proyecto.facultadesAcademicas.forEach(e => {
        this.getMenoresByIdMayor(e)
            .then(response => {
              response.data.response.forEach((item)=>{
                this.unidadesAcademicas.push(item);
              });
            }).catch(err => (console.log(err))
        );
      });
    },
    auditor(){
      console.log(this.proyecto);
    },
    showProject(){
      this.getProyectoById(this.id)
          .then(async response => {
            let dataEjes = [];
            response.data.response.estrategias.map(e =>
                dataEjes.push(e.ejeEstrategico.id)
            );
            this.proyecto.ejesEstrategicos = dataEjes.filter((value, index) => {
                  return dataEjes.indexOf(value) === index;
                }
            );
            let dataAreas = [];
            response.data.response.estrategias.map(e =>
                dataAreas.push(e.areaMisional.id)
            );
            this.proyecto.areasMisionales = dataAreas.filter((value, index) => {
                  return dataAreas.indexOf(value) === index;
                }
            );
            await this.setEstrategias();
            response.data.response.estrategias.forEach(e => {
              this.proyecto.estrategias.push(e.id)
            });
            console.table(response.data.response.unidadesAliadas);
            response.data.response.unidadesAliadas.forEach(e => {
              if(!this.proyecto.facultadesAcademicas.includes(e.centroMayor)){
                this.proyecto.facultadesAcademicas.push(e.centroMayor);
              }
            });
            await this.setUnidadesAcademicas();
            response.data.response.unidadesAliadas.forEach(e => {
              this.proyecto.unidadesAcademicas.push(e.id);
            });
            this.proyecto.nombre = response.data.response.nombre;
            this.proyecto.unidadEncargada = this.info.ccNombre;
            this.proyecto.nombreEncargado = response.data.response.nombreEncargado;
            this.proyecto.emailEncargado = response.data.response.emailEncargado;
            this.proyecto.phoneEncargado = response.data.response.anexoEncargado;
            this.proyecto.problematica = response.data.response.problematica;
            this.proyecto.objetivoGeneral = response.data.response.objetivoGeneral;
            this.proyecto.objetivo1 = response.data.response.objetivoEspecifico1;
            this.proyecto.objetivo2 = response.data.response.objetivoEspecifico2;
            this.proyecto.objetivo3 = response.data.response.objetivoEspecifico3;
            this.proyecto.resumen = response.data.response.resumen;
            this.proyecto.impacto = response.data.response.impacto;
          }).catch(err => {
            console.log("error obtener proyecto: ", err);
            Swal.fire({
              title: 'Proyecto',
              text: "Error al obtener proyecto: " + err.message,
              confirmButtonColor: '#EA7600',
              confirmButtonText: 'Aceptar',
              icon: 'error',
            });
          });
    },
    setSelectedProject(projectId){
      localStorage.setItem('idSelectedProject', projectId)
    }
  },
  async created() {
    await this.setEjesEstrategico();
    await this.setFacultadesAcademicas();
    await this.setAreasMisionales();
    await this.showProject();
    await this.setSelectedProject(this.id);
  },
  computed: {
    ...mapGetters({
      info: ['authentication/info']
    }),
    id() {
      return this.$route.params.id;
    },
  },
  watch: {

  }
}
</script>

<style scoped>

</style>